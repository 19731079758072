<template>
    <Transition>
        <div class="load-page" v-show="!isLoad">
            <div id="preloader">
                <span><img src="@/assets/imgs/home/logo_s.png"></span>
                <span><img src="@/assets/imgs/home/logo_u.png"></span>
                <span><img src="@/assets/imgs/home/logo_n.png"></span>
                <span><img src="@/assets/imgs/home/logo_j.png"></span>
                <span><img src="@/assets/imgs/home/logo_a.png"></span>
                <span><img src="@/assets/imgs/home/logo_d.png"></span>
                <span><img src="@/assets/imgs/home/logo_e.png"></span>
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { onMounted, ref } from "vue"

let t_img //定时器
let isLoad = ref(false) //图片是否加载完成

onMounted(()=>{
    isTimgLoad()
})
// 判断图片加载函数
const isTimgLoad = () => {
    try{
         // 查找所有图片迭代处理
        document.querySelectorAll('img').forEach((item)=>{
            if(!item.complete){ //判断图片是否加载完成
                throw Error()
            }
        })
        // 为true图片加载完成
        clearTimeout(t_img);//清楚定时器
        isLoad.value = true
    }catch(e){
        setTimeout(()=>{
            isTimgLoad() // 递归扫描
        },200)
    }
}
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.load-page{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background:#fff;
}
#preloader {
    position: relative;
    display: flex;
}

#preloader span {
    font-weight: bold;
    margin: 0 5px;
    opacity: 0.5;
    border-radius: 20px;
    animation: preloader 1.4s infinite ease-in-out;

}
#preloader span img{
    width:50px;
    height:50px;
    object-fit: contain;
}
#preloader span:nth-child(2) {
    animation-delay: -1.4s;
}

#preloader span:nth-child(3) {
    animation-delay: -1.2s;
    margin-right: 50px;
}

#preloader span:nth-child(4) {
    animation-delay: -1s;
}

#preloader span:nth-child(5) {
    animation-delay: -.8s;
}
#preloader span:nth-child(6) {
    animation-delay: -.6s;
}
#preloader span:nth-child(7) {
    animation-delay: -.4s;
}
#preloader span:nth-child(8) {
    animation-delay: -.2s;
}

@keyframes preloader {
    0% {
        opacity: 0.3;
        transform: translateY(0px);
        /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); */
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
    }

    50% {
        opacity: 1;
        transform: translateY(-10px);
        /* background: #f1c40f; */
        /* box-shadow: 0px 20px 3px rgba(0, 0, 0, 0.05); */
        filter: drop-shadow(0px 20px 3px rgba(0, 0, 0, 0.05));
    }

    100% {
        opacity: 0.3;
        transform: translateY(0px);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
    }
}
@media screen and (max-width: 768px) {
    #preloader span img{
        width:20px;
        height:50px;
    }
}
</style>