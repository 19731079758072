import { createRouter, createWebHistory } from "vue-router";

import Layout from '@/layout/index'
const routes = [
    {
        path:'/',
        component:Layout,        
        children:[{
            meta:{
                activeMenu:'首页'
            },
            path:'/',
            component:() => import('@/views/home/index'),
        },{
            path:'/about',
            component:() => import('@/views/about/index'),
            children:[{                
                path:'',
                component:() => import('@/views/about/about-info'),
                meta:{
                    activeMenu:'关于我们'
                },
            },{
                path:'team',
                redirect: '/',
                component:() => import('@/views/about/about-team'),
                meta:{
                    activeMenu:'关于我们'
                },
            },{
                path:'consociation',
                component:() => import('@/views/about/about-consociation'),
                meta:{
                    activeMenu:'关于我们'
                },
            }]
        },{
            path:'/project',
            component:() => import('@/views/project/index'),
            meta:{
                activeMenu:'新经济资产'
            },
        },{
            path:'/project/detail',
            component:() => import('@/views/project/project-detail'),
            meta:{
                activeMenu:'新经济资产'
            },
        },{
            path:'/esg',
            component:() => import('@/views/esg/index'),
            meta:{
                activeMenu:'ESG'
            },
            children:[{                
                path:'',
                component:() => import('@/views/esg/esg-vision'),
                meta:{
                    activeMenu:'ESG'
                },
            },{                
                path:'strategy',
                component:() => import('@/views/esg/esg-strategy'),
                meta:{
                    activeMenu:'ESG'
                },
            },{                
                path:'share',
                component:() => import('@/views/esg/esg-share'),
                meta:{
                    activeMenu:'ESG'
                },
            }]
        },{
            path:'/news',
            component:() => import('@/views/news/index'),
            meta:{
                activeMenu:'公司动态'
            },
            children:[{                
                path:'',
                component:() => import('@/views/news/news-company'),
                meta:{
                    activeMenu:'公司动态'
                },
            },{                
                path:'industry',
                component:() => import('@/views/news/news-industry'),
                meta:{
                    activeMenu:'公司动态'
                },
            },{                
                path:'detail',
                component:() => import('@/views/news/news-detail'),
                meta:{
                    activeMenu:'公司动态'
                },
            }]
        },
        {                
            path:'/contact',
            component:() => import('@/views/contact/index'),
            meta:{
                activeMenu:'联系我们'
            },
        },
        {                
            path:'/join',
            component:() => import('@/views/join/index'),
        },{                
            path:'/join/market',
            component:() => import('@/views/join/job-market'),
        },{                
            path:'/search',
            component:() => import('@/views/search/index'),
        }]
    }
]

const router = createRouter({
    history:createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        if (savedPosition) {
            return savedPosition
        }else if (to.query.hash) {
            return {
                el: "#"+to.query.hash,
                behavior: 'smooth',
            }
        }else{
            return { top: 0}
        }
    }
})
let isReload = false
router.beforeEach((to, from) => {        
    // 不是第一次进入 并且 不是同一页面 就走刷新的流程跳转
    if(isReload && to.path !== from.path){
        isReload = false
        location.href = location.origin+to.fullPath
        return false
    }
    isReload = true
  })
export default router