import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/styles/normalize.css'
import '@/styles/index.less'

import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
import i18n from '@/plugins/i18n'
import lazyImg from '@/components/LazyImg'

const app = createApp(App)

app.component('lazyImg',lazyImg)

app.use(VueFullPage).use(i18n).use(router).mount('#app')