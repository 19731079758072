<template>
    <div class="header-container wrapper">
        <div class="all-menu" :class="{ open: menuShow }" @click="toggleMenu">
            <span></span>
        </div>
        <MobileMenu :nav-list="navList" @close="toggleMenu"></MobileMenu>
        <div class="logo">
            <router-link to="/">
                <img src="@/assets/imgs/logo.png" />
            </router-link>
        </div>
        <MainMenu :nav-list="navList" />
        <div class="action">
            <div class="search" @click="searchShow = true"><img src="@/assets/imgs/icon/icon-search.png" alt=""></div>
            <div class="line"></div>
            <div class="language">{{ $t('language') }}
                <div class="language-box">
                    <div @click="changeLang('zhCn')"><span>中</span></div>
                    <div @click="changeLang('en')"><span to="/en">EN</span></div>
                </div>
            </div>
        </div>
    </div>
    <MainSearch v-model="searchShow"></MainSearch>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import MainMenu from './MainMenu.vue'
import MobileMenu from './MobileMenu.vue'
import MainSearch from './MainSearch.vue'
const { t, locale } = useI18n()
const route = useRoute()
const searchShow = ref(false)
const menuShow = ref(false)
const reload = inject('reload')
const toggleMenu = () => {
    menuShow.value = !menuShow.value
    if (menuShow.value) {
        document.body.classList.add('openMenu')
    } else {
        document.body.classList.remove('openMenu')
    }
}
const navList = computed(() => {
    return [{
        name: t('menu.home'),        
        path: '/',
        activeName:'首页',        
    }, {
        name: t('menu.about'),
        path: '/about',
        activeName:'关于我们',     
        children: [
            { name: t('about.intro'), path: '/about', activeName:'关于我们',},
            { name: t('about.vision'), path: '/about?hash=vision', activeName:'关于我们',},
            // { name: t('about.team'), path: '/about/team', activeName:'关于我们',},
            { name: t('about.consociation'), path: '/about/consociation', activeName:'关于我们',},
        ]
    }, {
        path: '/project',
        name: t('menu.economic'),
        activeName:'新经济资产',
    }, {
        path: '/esg',
        name: t('menu.esg'),
        activeName:'ESG',
        children: [
            { name: t('esg.vision'), path: '/esg', activeName:'ESG',},
            { name: t('esg.strategy'), path: '/esg/strategy', activeName:'ESG',},
            { name: t('esg.share'), path: '/esg/share', activeName:'ESG',},
        ]
    }, {
        path: '/news',
        name: t('menu.news'),
        activeName:'公司动态',
        // children: [
        //     { name: t('news.companyNews'), path: '/news' },
        //     { name: t('news.tradeNews'), path: '/news/industry' },
        // ]
    }, {
        path: '/contact',
        name: t('menu.contact'),
        activeName:'联系我们',
    }]
})

const changeLang = (lang) => {
    locale.value = lang
    localStorage.setItem("lang", lang)
    reload()
}


watch(route, () => {
    menuShow.value = false
    document.body.classList.remove('openMenu')
})
</script>
<style lang="less" scoped>
.header-container {
    position: sticky;
    top: 0;
    display: flex;
    height: 100px;
    border-bottom: 1px solid rgba(60, 60, 60, .12);
    box-sizing: border-box;
    z-index: 9;
    background: #fff;
    transition: all .3s;

    &.header-fixed {
        height: 70px;
        background: rgba(255, 255, 255, .9);
    }

    .logo {
        display: flex;
        align-items: center;
        width: 150px;

        img {
            width: 100%;
        }
    }

    .action {
        display: flex;
        align-items: center;

        .search {
            cursor: pointer;

            img {
                width: 16px;
            }
        }

        .line {
            width: 1px;
            height: 16px;
            background: #aaaaaa;
            margin: 0 15px;
        }

        .language {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            line-height: 40px;

            &::after {
                content: '';
                border: 5px solid transparent;
                border-left-color: #333333;
                margin-left: 5px;
            }

            .language-box {
                display: none;
                position: absolute;
                top: 100%;
                right: 0;
                border-radius: 8px;
                padding: 12px;
                min-width: 52px;
                border: 1px solid transparent;
                background: #fff;
                box-shadow: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
                transition: background-color .5s;
                line-height: 24px;

                div {
                    margin-bottom: 6px;

                    .vt-link-icon {
                        display: inline-block;
                        margin-top: -2px;
                        margin-left: 4px;
                        width: 11px;
                        height: 11px;
                        fill: rgba(60, 60, 60, .33);
                        transition: fill .25s;
                    }
                }

                span {
                    color: #222;

                    &:hover {
                        color: @color;
                    }
                }
            }

            &:hover {
                .language-box {
                    display: block;
                }
            }
        }
    }

}

.all-menu {
    position: relative;
    z-index: 11;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 53px;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .bar() {
        display: block;
        width: 24px;
        height: 2px;
        background: #000;
        transition: all 0.3s;
    }

    &::before {
        content: "";
        .bar()
    }

    &::after {
        content: "";
        .bar()
    }

    span {
        .bar()
    }

    &.open {
        span {
            opacity: 0;
        }

        &::before {
            transform: translateY(8px) rotate(45deg);
        }

        &::after {
            transform: translateY(-8px) rotate(-45deg);
        }
    }
}

@media screen and (max-width: 768px) {
    .header-container {
        align-items: center;
        height: 70px;

        .logo {
            flex: 1;
            justify-content: center;

            img {
                width: 100px;
            }
        }
    }

    /deep/ .menu-container {
        display: none;
    }

    .action {
        // display: none !important;
    }
}
</style>