<template>
    <div class="sitecontent" :class="$i18n.locale">
        <LoadPage v-show="!loadPageNotFirstLoad && $route.path !== '/project/detail'"/>
        <Header></Header>
        <router-view v-if="isRouterAlive"/>
        <Footer></Footer>
    </div>
</template>
<script setup>
import LoadPage from './components/LoadPage/index.vue'
import Header from './components/Header/index.vue'
import Footer from './components/Footer/index.vue'

import { WOW } from "wowjs";
import "wowjs/css/libs/animate.css";
import { watch, nextTick, provide, ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute()
const loadPageNotFirstLoad = sessionStorage.getItem('loadPageNotFirstLoad') 
sessionStorage.setItem('loadPageNotFirstLoad',true)
const scrollHeader = ()=>{
    if(document.documentElement.scrollTop > 0){
        document.querySelector('.header-container').classList.add('header-fixed')
    }else{
        document.querySelector('.header-container').classList.remove('header-fixed')
    }
}
watch(route, () => {
    if(route.path !== '/'){
        nextTick(() => {
            new WOW({
                live: true, // https://wowjs.uk/docs.html
            }).init()
        })
        document.addEventListener('scroll',scrollHeader)
    }else{
        document.removeEventListener('scroll',scrollHeader)
    }
    document.body.classList.remove('openMenu')
}, { immediate: true })

const isRouterAlive = ref(true)
const reload = () =>{
    isRouterAlive.value = false;
    nextTick(()=>{
        isRouterAlive.value = true
    })
}
provide('reload',reload)
</script>
<style>
.fp-watermark{
    display: none;
}
</style>