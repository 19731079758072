<template>
    <Teleport to="#app">
        <div class="menu-dialog">
            <div class="menu-dialog-conten">
                <div class="menu-dialog-head"></div>
                <div class="menu-content">
                    <ul class="nav">
                        <li v-for="(item, index) in navList" :key="index" :class="{ active: activeIndex === index }">
                            <div class="nav-item" @click="navClick(index, item)">
                                {{ item.name }}
                                <span class="more" v-if="item.children">
                                    <span class="h"></span>
                                    <span class="h v transform"></span>
                                </span>
                            </div>
                            <div class="subnav">
                                <ul>
                                    <li v-for="child in item.children" :key="child">
                                        <div class="child-item" @click="navClick(index, child)">{{ child.name }}</div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div class="flex items-center" style="padding:30px 16px 16px;">

                    </div>
                    <div class="middle-content flex items-center justify-between">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useRouter } from 'vue-router';
const { navList } = defineProps(['navList'])
const emit = defineEmits(['close'])
const router = useRouter()
let activeIndex = ref(null)
const navClick = (index, item) => {
    activeIndex.value = index
    if (!item.children) {
        router.push(item.path)
        emit('close')

    }

}
</script>
<style lang="less" scoped>

.menu-dialog {
    position: fixed;
    top: 0;
    left:0;
    width: calc(100% - 56px);
    z-index: 10;
    transition: .5s;
    transform: translateX(-100%);
    .menu-dialog-head {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 69px;
        padding: 0 16px;
        background: #fff;
        .language-icon {
            width: 20px;
        }
    }

    .menu-dialog-conten {
        position: relative;
        z-index: 100;
        width: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        flex-direction: column;

        .menu-content {
            flex: 1;
            overflow: auto;
            border-right: 0;
            background: #fff;

            li {
                border-top: 1px solid rgba(0, 0, 0, .08);
                min-height: 49px;
                line-height: 51px;
                font-size: 16px;

                .nav-item {
                    padding: 0 25px;

                    .more {
                        width: 12px;
                        height: 12px;
                        position: relative;
                        float: right;
                        top: 20px;

                        .h {
                            position: absolute;
                            width: 12px;
                            height: 2px;
                            transform: translate3d(0, 5px, 0);
                            background: #474747;
                            transition: all .6s;

                            &.v {
                                transform: rotate3d(0, 0, 1, 90deg) translate3d(5px, 0, 0);
                            }
                        }
                    }

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        background-color: #A2A2A2;
                        border-radius: 50%;
                        margin-right: 20px;
                    }
                }

                .subnav {
                    display: grid;
                    grid-template-rows: 0fr;
                    transition: all .6s;

                    ul {
                        overflow: hidden;

                        .child-item {
                            text-indent: 1em;

                            &::before {
                                content: '-';
                                display: inline-block;
                                margin-right: 20px;
                            }
                        }
                    }
                }

                &.active {
                    .nav-item {
                        color: @color;

                        .more {
                            .h {
                                &.v {
                                    transform: rotate3d(0, 0, 0, 0) translate3d(0, 5px, 0);
                                }
                            }
                        }
                    }

                    .subnav {
                        grid-template-rows: 1fr;
                    }
                }
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.5s;
    opacity: 1;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.middle-content {
    padding: 16px 16px 30px 16px;

    .language {
        margin-right: 50px;
    }

    .el-dropdown-link {
        font-size: 16px;
        color: #000;
    }
}

.register-btn {
    width: 180px;
    height: 45px;
    font-size: 16px;
    border-radius: 10px;
}
</style>