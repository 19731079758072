<template>
    <Teleport to="#app">
        <Transition>
            <div class="popupBg" v-if="props.modelValue">
                <div class="popupArea" @click="close">
                    <div class="popupItem" @click.stop>
                        <div class="qrcode">
                            <img src="@/assets/imgs/qrcode.jpg" alt="">
                        </div>
                        <p>微信扫一扫</p>
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
function close() {
    emit('update:modelValue', false)
}
</script>
<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;

    .popupArea {
        .popupItem {
            margin-top: -56px !important;
        }
    }
}

.popupBg {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 100;

    .popupArea {
        width: 100%;
        height: 100%;

        .popupItem {
            box-shadow: 0 5px 18px 0 rgba(0, 0, 0, .1);
            padding: 10px;
            overflow: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            background: #fff;
            transition: margin 0.36s ease, background 1s ease, border-color 1s ease;
            text-align: center;
        }

        @media screen and (max-width: 768px) {
            
            .popupItem {
                width: 90%;
                height: auto;
            }
        }
    }
}
</style>