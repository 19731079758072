<template>
    <div class="menu-container">
        <ul class="menu" ref="MainHeader">
            <li v-for="(item, index) in props.navList" :class="{ active: activeIndex === index || hoverIndex === index }"
                :key="item" @click="setActiveNav(index)" @mouseenter="setCurrentNav(index)"
                @mouseleave="handleLeaveMainHeader">
                <router-link :to="item.path || '/'">{{ item.name }}</router-link>
            </li>
        </ul>
        <div class="nav-underline" :style="navLineStyle"></div>
    </div>
    <div class="sub-nav-container" v-show="hoverMenu.length > 0" @mouseenter="setCurrentNav(hoverIndex)"
        @mouseleave="handleLeaveMainHeader">
        <ul class="sub-nav" :style="subNavStyle">
            <li v-for="item in hoverMenu" :key="item">
                <router-link :to="item.path || '/'">{{ item.name }}</router-link>
            </li>
        </ul>
    </div>
</template>
<script setup>
import { computed, onMounted, ref, defineProps, watch } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps(['navList'])
const route = useRoute()
let activeIndex = computed(() => {
    return props.navList.findIndex(item => item.activeName === route.meta.activeMenu)
})
let hoverIndex = ref(-1)
let hoverMenu = ref([])
let currentNavStyle = ref(null)
let activeNavStyle = ref(null)
const MainHeader = ref(null)
onMounted(() => {
    setActiveNav(activeIndex.value)
})
function setActiveNav(index) {
    // 点击导航时，设置激活样式
    activeNavStyle.value = getNavStyle(index)
}

let menuMoveOut = null
function setCurrentNav(index) {
    clearTimeout(menuMoveOut)
    hoverIndex.value = index
    currentNavStyle.value = getNavStyle(index)
    hoverMenu.value = props.navList[index].children || []
}
function handleLeaveMainHeader() {
    // 鼠标离开导航栏时，重置下划线状态
    menuMoveOut = setTimeout(() => {
        hoverIndex.value = -1
        currentNavStyle.value = getNavStyle(activeIndex.value)
        hoverMenu.value = []
    }, 50)

}
const navLineStyle = computed(() => {
    let style = currentNavStyle.value || activeNavStyle.value
    return {
        width: `${style ? style.width : 0}px`,
        left: `${style ? style.left : 0}px`,
    }
})
const subNavStyle = computed(() => {
    const left = document.querySelector('.menu-container') ? document.querySelector('.menu-container').offsetLeft : 0
    let style = currentNavStyle.value || activeNavStyle.value
    return {
        left: `${style ? style.left + left : left}px`,
    }
})
function getNavStyle(index) { // 根据导航的 index 移动下划线    
    if (index == -1) return { width: 0, left: 0 }
    // 导航之间的间距
    let padding = 60
    let childNodes = MainHeader.value.children
    // 根据当前导航的宽度，设置下划线的宽度
    let width = childNodes[index].clientWidth - padding
    // 设置下划线的默认位置
    let left = childNodes[index].offsetLeft + padding / 2
    return { width, left }
}
watch(route,()=>{
    currentNavStyle.value = null
    setActiveNav(activeIndex.value)
})
</script>
<style lang="less" scoped>
.menu-container {
    margin-left: 95px;
    display: flex;
    position: relative;
    flex: 1;

    .menu {
        display: flex;

        li {
            display: flex;
            align-items: center;
            padding: 0 30px;

            a {
                color: #333333;
            }

            &.active a {
                color: @color;
            }
        }
    }

    .nav-underline {
        position: absolute;
        bottom: 0;
        height: 2px;
        background: @color;
        transition: all .2s ease-out;
    }
}

.sub-nav-container {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: rgba(255, 255, 255, .8);
    z-index: 9;
    height: 70px;

    .sub-nav {
        position: absolute;
        height: 70px;
        display: flex;
        align-items: center;

        li {
            margin-right: 58px;

            a {
                color: #333333;
                transition: all .3s;

                &:hover {
                    color: @color;
                }
            }
        }
    }
}</style>