<template>
    <div class="img-box" v-lazy="vm" :data-src="src">
        <div class="load-img" v-if="slotShow"></div>
        <slot name="err" v-if="errFlag"></slot>
    </div>
</template>
<script>
export default {
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            slotShow: true,
            errFlag: false,
            vm: null,
        };
    },
    created() {
        this.vm = this;
    },

    // 这个指令可以放到全局
    directives: {
        lazy: {
            mounted(el, { value }) {
                const imgSrc = el.dataset.src;
                const observer = new IntersectionObserver(([{ isIntersecting }]) => {
                    if (isIntersecting) {
                        // 动态创建 img 标签
                        let img = document.createElement("img");
                        img.src = imgSrc;
                        img.style.width = "100%";
                        img.style.height = "100%";
                        img.style.objectFit = "inherit";
                        // 添加图片加载完成事件，加载完成，让加载前的样式隐藏
                        img.onload = function () {
                            value.slotShow = false;
                            el.appendChild(img);
                        };
                        (img.onerror = function () {
                            // 加载失败得时候  失败后得样式显示
                            value.errFlag = true;
                            // 让加载前得样式隐藏，
                            value.slotShow = false;
                        }),

                            observer.unobserve(el);
                    }
                });
                observer.observe(el);
            },
        },
    },
    methods: {
    },
};
</script>
<style lang="less" scoped>
.img-box {
    display: flex;
    position: relative;
    overflow: hidden;
}

.load-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background:url(~@/assets/imgs/loading.svg) no-repeat center center;
}
.icon-loading{
    width: 20px !important;
    height: 20px !important;
}
</style>