<template>
    <div class="footer">
        <div class="container copyright">
            <div><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备2023016351号-1</a>  |    © 2023 {{$i18n.locale === 'zhCn'?'森瑶中国版权所有':'Sunjade All Rights Reserved'}}</div>
            <div class="share-container">
                <span class="share-item" @click="show = true"></span>
            </div>

        </div>
    </div>    
    <WechatPopup v-model="show" />
</template>
<script setup>
import { ref } from 'vue'
import WechatPopup from './WechatPopup.vue'
const show = ref(false)
</script>
<style lang="less" scoped>
.footer{
    line-height: 74px;
    background:#ededed;
    .copyright{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .share-item{
            font-size: 23px;
            line-height: 38px;
            display: block;
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            transition: 0.36s ease;
            color: #9e9e9e;
            border: 1px solid rgba(150, 150, 150, 0);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            background:#363636 url(~@/assets/imgs/icon/icon-wechat.png) no-repeat center center;
            &:hover{
                background-color: rgba(105, 105, 105, 1);
            }
        }
        a{
            color:#333;
        }
    }
    @media screen and (max-width: 768px) {
        line-height: 1.3;
        padding: 16px 0;
        font-size: 14px;
    }
}
</style>