<template>
    <Transition>
        <div class="search-bg" v-show="props.modelValue">
            <div class="close" @click="close"><img src="@/assets/imgs/icon/icon-close.png" alt=""></div>
            <form @submit.prevent="search">
                <div class="search-container">
                    <input type="text" class="search-input" v-model="key" :placeholder="$t('searchPlaceholder')">
                    <button class="search-btn" @click="search">{{ $t('Search') }}</button>
                </div>
            </form>
        </div>
    </Transition>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useRouter } from 'vue-router'
const props = defineProps(['modelValue'])
const emit = defineEmits('update:modelValue')
const close = () => {
    emit('update:modelValue', false)
}
const router = useRouter()
const key = ref('')
const search = () => {
    close()
    router.push({path:'/search',query:{key:key.value}})
}
</script>
<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;

    .search-container {
        margin-top: -16px !important;
    }
}

.search-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .8);
    z-index: 9;

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 24px;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    .search-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, .1);
        padding: 35px;
        font-size: 24px;
        display: flex;
        margin-top: 0;
        transition: margin-top .6s;

        .search-input {
            width: 920px;
            height: 80px;
            border: 0;
            border-radius: 5px;
            background: #fff;
            margin-right: 12px;
            padding: 0 15px;
            box-sizing: border-box;

        }

        .search-btn {
            width: 195px;
            height: 80px;
            border: 0;
            border-radius: 5px;
            color: #fff;
            background: @color;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 768px) {
        .search-container {
            padding: 16px;
            width: 80%;
            font-size: 16px;

            .search-input {
                width: 80%;
                height: 50px;
                border: 0;
                border-radius: 5px;
                background: #fff;
                margin-right: 12px;
                padding: 0 15px;
                box-sizing: border-box;

            }

            .search-btn {
                width: 20%;
                height: 50px;
                border: 0;
                border-radius: 5px;
                color: #fff;
                background: @color;
                cursor: pointer;
            }
        }
    }
}</style>